<!-- 完成或未完成列表表头 -->
<template>
  <div class="header">
    <h2>统计概览</h2>

    <div class="button">
      <div class="search-input" v-if="!isDisabled">
        <Select
          placeholder="全部机构"
          @on-change="handleChange"
          filterable
          clearable
          :disabled="isDisabled"
        >
          <Option
            :label="item.orgName"
            :value="item.orgName"
            v-for="item in orgList"
            :key="item.id"
          ></Option>
        </Select>
      </div>
      <p v-if="isDisabled">所属机构：{{org}}</p>
      <CommonHeader />
    </div>
  </div>
</template>
  
<script setup>
import { ref, watch, inject, onMounted } from "vue";
import { useRouter } from "vue-router";
import bus from "@/utils/bus.js";
import CommonHeader from "@/components/commonHeader.vue";
// let isEmail = ref(false);
// let isPerson = ref(false);
let isShow = ref(0); //0代表未完成，1代表已完成

let org = ref("");
let orgList = ref([]);
let axios = inject("axios");
const handleChange = (val) => {
  //清除下拉框时会导致绑定的值变成undefined
  org.value = val != null && val != undefined ? val : "";
  bus.emit("changeOrg", org.value);
};

watch(org, (val) => {
  // bus.emit("changeOrg", val);
});

const getOrgList = (query) => {
  let url = `/statisticalOverview/getOrg?orgName=${query}`;
  axios
    .get(url)
    .then((res) => {
      // console.log(res);
      if (res.data.code == 200) {
        orgList.value = res.data.data;
      } else {
        orgList.value = [];
      }
    })
    .catch((error) => {
      orgList.value = [];
    });
};
let isDisabled = ref(false);
//获取用户信息
const getUserInfo = () => {
  if (localStorage.getItem("User")) {
    let user = JSON.parse(localStorage.getItem("User"));
    if (user != null && user != undefined) {
      let role = user.userInfo.role;
      if (role == "general") {
        isDisabled.value = true;
        org.value = user.org;
        bus.emit("changeOrg", org.value);
      } else {
        isDisabled.value = false;
        bus.emit("changeOrg", org.value);
      }
    }
  }
};

onMounted(() => {
  getOrgList("");
  getUserInfo();
});
</script>
  
  
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 0 0;

  > h2 {
    font-family: PingFangSC-Medium;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0px;
    color: #111111;
  }
}

.button {
  display: flex;
  align-items: center;
  >p{
      font-size: 14px;
      color: #555555;
      font-weight: bold;
  }
  > div {
    margin-left: 20px;
  }

  .btn-add {
    color: #fff;
    cursor: pointer;

    i {
      padding-right: 5px;
    }
  }

  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}

.search-input {
  background: #f6f6f6;
  border-radius: 6px;
  width: 400px;
  height: 40px;
  display: flex;
  background-image: linear-gradient(#f6f6f6, #f6f6f6),
    linear-gradient(#2c399d, #2c399d);

  :deep(.ivu-select-selection),
  :deep(.ivu-select-placeholder),
  :deep(.ivu-select-placeholder) {
    height: 40px;
    line-height: 40px;
    border: none;
    background: #f6f6f6;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    color: #555555;
  }
}
</style>