<template>
  <div class="tree-box">
    <div class="back" @click="goBack" :class="isFirst ? 'hidden' : ''">
      <Icon type="ios-arrow-back" />
    </div>
    <div
      ref="tree"
      class="tree"
      :class="isFirst ? 'all' : ''"
      v-if="isShow"
    ></div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, watch, nextTick,onBeforeUnmount } from "vue";
import echarts from "echarts";
// import data from "./tree";
import bus from "@/utils/bus";
import { Message } from "view-ui-plus";
let tree = ref(null);
let chart = ref(null);
let isShow = ref(false);
let obj = ref({}); //图的数据
let orgName = ref("");
let isFirst = ref(true); //判断其是否是一级目录

watch(obj, (val, oldVal) => {
  if (val.length > 0) {
    isFirst.value = val[0].level == 1 ? true : false;
  }
});

const render = () => {
   let myChart = (chart.value = echarts.init(tree.value));
  let Newdata = obj.value.map((item) => {
    return item;
  });
  chart.value.hideLoading();
  let option = {
    backgroundColor: "#c5dcf4",

    tooltip: {
      trigger: "item",
      extraCssText:
        "padding:10px 30px;height:auto;line-height:24px;background-color:#fff;color:#555555;font-family: PingFangSC-Regular;",
      formatter: function (params) {
        let str = `
             <div class="intro">
                <p>分类：${params.data.name}</p>
                <p>目录条数：${params.data.value}条</p>
                <p>数据大小：${params.data.dataSize}GB</p>
              </div>
          `;
        return str;
      },
    },
    visualMap: {
      //有下面两种写法
      show: false,
      // min: 0,
      // max: 25,
      // splitNumber: 5,
      inRange: {
        //这种写法才是目前主流的写法
        color: [
          "#c5dcf4",
          "rgba(197,220,244,0.9)",
          "rgba(197,220,244,0.8)",
          "rgba(197,220,244,0.6)",
          "rgba(197,220,244,0.5)",
          "rgba(197,220,244,0.4)",
        ].reverse(),
      },
    },

    series: [
      {
        type: "treemap",
        width: "100%",
        height: "100%",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        roam: false, //是否开启拖拽漫游（移动和缩放）
        nodeClick: false, //点击节点后的行为,false无反应
        breadcrumb: {
          show: false,
        },
        label: {
          //描述了每个矩形中，文本标签的样式。
          normal: {
            show: true,
            // position: ["50%", "50%"],
            formatter: (params) => {
              return params.name + "\n\n" + params.value;
            },
            color: "#467bb2",
            fontSize: 20,
            // fontWeight: "bold",
            ellipsis: true,
            fontStyle: "normal",
            fontFamily: "PingFangSC-Regular",
          },
          emphasis: {
            show: true,
            color: "#fff",
          },
        },
        itemStyle: {
          normal: {
            show: true,
            textStyle: {
              color: "#467bb2",
              fontSize: 16,
            },
            borderWidth: 1,
            borderColor: "#fff",
            color: "#c5dcf4",
          },

          emphasis: {
            label: {
              show: true,
            },
            color: "#3d55a8",
          },
        },
        data: Newdata,
      },
    ],
  };
  myChart.setOption(option, true);
  myChart.off("click");
  myChart.on("click", (params) => {
    // console.log(params);
    if (params.data.level == 1) {
      getSecondLevel(params.data.name, params.data.level);
    }
    if (params.data.level == 2) {
      getThirdLevel(params.data.name, params.data.level);
    }
  });
};
let axios = inject("axios");
//查询一级数据
const getFirstLevel = (type, level) => {
  if (chart.value != null) {
    chart.value.showLoading();
  }
  let url = `/statisticalOverview/typeStatisticsFir`;
  let params = {
    dataType: type,
    level: level,
    orgName: orgName.value,
  };
  axios.get(url, { params }).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      obj.value = res.data.data;
      //有数据时显示下一级的数据，没有数据就不显示或者直接显示当前数据
      if (obj.value.length > 0) {
        isShow.value = true;
        nextTick(() => {
          render();
        });
      
      } else {
        // Message.error({
        //   background: true,
        //   content: "此数据没有对应的下属层级数据",
        //   duration: 5,
        // });
        isShow.value = false;
      }
      if (chart.value != null) {
        chart.value.hideLoading();
      }
    }
  });
};
const getSecondLevel = (type, level) => {
  if (chart.value != null) {
    chart.value.showLoading();
  }
  isShow.value = false;
  let url = `/statisticalOverview/typeStatisticsSec`;
  let params = {
    dataType: type,
    level: level,
    orgName: orgName.value,
  };
  axios.get(url, { params }).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
     let data = res.data.data;
      if (data.length > 0) {
        obj.value =data;
        isShow.value = true;
        nextTick(() => {
          render();
        });
      } else {
        // isShow.value = false;
        Message.error({
          background: true,
          content: "此数据没有对应的下属层级数据",
          duration: 5,
        });
      }
      chart.value.hideLoading();
    }
  });
};
const getThirdLevel = (type, level) => {
  if (chart.value != null) {
    chart.value.showLoading();
  }
  let url = `/statisticalOverview/typeStatisticsThi`;
  let params = {
    dataType: type,
    level: level,
    orgName: orgName.value,
  };
  axios.get(url, { params }).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      let data = res.data.data;
      if (data.length > 0) {
        obj.value =data;
        isShow.value = true;
        nextTick(() => {
          render();
        });
      } 
      chart.value.hideLoading();
    }
  });
};

const goBack = () => {
  if (obj.value.length > 0) {
    let newObj = obj.value[0];
    if (newObj.level == 3) {
      getSecondLevel(newObj.name, newObj.level);
    }
    if (newObj.level == 2) {
      getFirstLevel(newObj.name, newObj.level);
    }
  }
};

let isDisabled = ref(false);
//获取用户信息
const getUserInfo = () => {
  if (localStorage.getItem("User")) {
    let user = JSON.parse(localStorage.getItem("User"));
    if (user != null && user != undefined) {
      let role = user.userInfo.role;
      if (role == "general") {
        isDisabled.value = true;
      } else {
        isDisabled.value = false;
      }
    }
  }
};

onMounted(() => {
  getUserInfo();
  bus.on("changeOrg", (val) => {
    orgName.value = val;
    if (!isDisabled.value) {
      getFirstLevel("", "");
    }
  });
  window.onresize = () => {
    if (chart.value != null) {
      chart.value.resize();
    }
  };
  getFirstLevel("", "");
});
onBeforeUnmount(()=>{
  bus.all.delete('changeOrg');
})
</script>

<style lang="scss" scoped>
.tree-box {
  height: 580px;
  padding: 20px 0;
  display: flex;
  .back {
    width: 50px;
    height: 100%;
    background: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      font-size: 36px;
    }
  }
}
.tree {
  width: calc(100% - 50px);
  // flex-grow: 1;
  height: 100%;
}
.hidden {
  width: 0 !important;
  display: none !important;
}
.all {
  width: 100% !important;
}
</style>