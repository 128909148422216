<template>
  <div ref="refbar" class="bar"></div>
</template>
  
  <script setup>
import { ref, onMounted, watch ,markRaw} from "vue";
import echarts from "echarts";
let refbar = ref(null);
let chart = ref(null);
let obj = ref({});

const props = defineProps({
  list: Object,
});

watch(
  () => props.list,
  (val, oldVal) => {
    obj.value = val;
    render();
  },
  { deep: true }
);

const render = () => {
  chart.value = markRaw(echarts.init(refbar.value));
  
  let newObj = { ...obj.value };
  let dataAxis = newObj.dataAxis;
  let data = newObj.data;

  let yMax = 500;
  let dataShadow = [];
  for (let i = 0; i < data.length; i++) {
    dataShadow.push(yMax);
  }
  let option = {
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        label: {
          show: false,
        },
        lineStyle: {
          color: "#ddd",
        },
      },
      backgroundColor: "rgba(255,255,255,1)",
      padding: [5, 10],
      textStyle: {
        color: "#f2a750",
      },
    },
    xAxis: {
      data: dataAxis,
      axisLabel: {
        inside: false,
        color: "#fff",
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      //   z: 10,
    },
    yAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
        color: "#999",
      },
      splitLine: {
        show: false,
      },
    },

    series: [
      {
        name: "未完成数量",
        type: "bar",
        showBackground: false,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#ffeed7" },
            // { offset: 0.5, color: "#188df0" },
            { offset: 1, color: "#f2a750" },
          ]),
        },
        emphasis: {
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#2378f7" },
              { offset: 0.7, color: "#2378f7" },
              { offset: 1, color: "#83bff6" },
            ]),
          },
        },
        data: data,
      },
    ],
  };
  chart.value.setOption(option, true);
};
onMounted(() => {
  window.addEventListener("resize", () => {
    if (chart.value != null) {
      chart.value.resize();
    }
  });
 
});
</script>
  
  <style lang="scss" scoped>
.bar {
  height: 80px;
  margin-left: -10px;
  margin-right: -10px;
}
</style>