<template>
  <div class="wrap-content">
    <Header />
    <div class="main">
      <div class="count">
        <div class="count-item">
          <p>未完成数量</p>
          <h3>{{ datalist.total }}条</h3>
          <Bar :list="datalist.lineData" />
          <p>
            本月新增<span class="bold">{{ datalist.increase }}</span> 条
          </p>
        </div>
        <div class="count-item">
          <p>已提交数量</p>
          <h3>{{ aduitList.total }}条</h3>
          <BrokenLine :list="aduitList.lineData" />
          <p>
            超过7日未复核<span class="bold">{{ aduitList.increase }}</span> 条
          </p>
        </div>
        <div class="count-item">
          <p>复核通过率</p>
          <h3>{{ throughList.total }}%</h3>
          <Myprogress :list="throughList.lineData" />
          <p>
            近30天复核通过率
            <span class="bold">{{ throughList.increase }} %</span>
          </p>
        </div>
        <div class="count-item">
          <p>平均复核时长</p>
          <h3>{{ aduitDate.total }}天</h3>
          <auditDate :list="aduitDate.lineData" />
          <p>
            近30天平均复核时长
            <span class="bold">{{ aduitDate.increase }}</span> 天
          </p>
        </div>
      </div>
      <Tree />
    </div>
    <Footer />
  </div>
</template>
    
    <script setup>
import { ref, inject, onBeforeUnmount, onMounted, nextTick } from "vue";
import Header from "./Header.vue";
import Footer from "@/components/footer.vue";
import bus from "@/utils/bus.js";
import Tree from "./components/tree.vue";
import Bar from "./components/bar.vue";
import BrokenLine from "./components/brokenLine.vue";
import auditDate from "./components/auditDate.vue";
import Myprogress from "./components/progress.vue";
let org = ref("");
let datalist = ref({});
let aduitList = ref({});
let throughList = ref({});
let aduitDate = ref({});
let axios = inject("axios");

const getStaticData = () => {
  let url = `/statisticalOverview/numericalStatistics?orgName=${org.value}`;
  axios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      let data = res.data.data;
      // console.log(data)
      if (data != null) {
        datalist.value = data.dm;
        aduitList.value = data.tm;
        throughList.value = data.cm;
        aduitDate.value = data.am;
      }
    }
  });
};

let isDisabled = ref(false);
//获取用户信息
const getUserInfo = () => {
  if (localStorage.getItem("User")) {
    let user = JSON.parse(localStorage.getItem("User"));
    if (user != null && user != undefined) {
      let role = user.userInfo.role;
      if (role == "general") {
        isDisabled.value = true;
      } else {
        isDisabled.value = false;
      }
    }
  }
};

onMounted(() => {
  getUserInfo();
  bus.on("changeOrg", (val) => {
    org.value = val;
    getStaticData();
  });
  getStaticData();
});
onBeforeUnmount(()=>{
  bus.all.delete('changeOrg');
})
</script>
    
    <style lang="scss" scoped>
.wrap-content {
  padding: 0 40px;
  background: #fff;
  min-height: 100vh;
  
}
.main {
  margin-top: 30px;
  min-height: calc(100vh - 145px);
}
.count {
  display: flex;
  .count-item {
    width: 25%;
    border-radius: 10px;
    background-color: #fafafa;
    // height: 270px;
    margin-right: 20px;
    padding: 30px;
    p {
      text-align: center;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      color: #555555;
      font-family: PingFangSC-Regular;
    }
    p:last-child {
      margin-top: 20px;
    }
    h3 {
      color: #111111;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: 30px;
      font-weight: normal;
      font-stretch: normal;
      margin-top: 20px;
      margin-bottom: 28px;
    }
  }
  .count-item:last-child {
    margin-right: 0;
  }
}
</style>