<template>
  <div class="box" ref="box">
    <div class="access"></div>
    <div class="process" ref="process" :style="{ width: process }"></div>
    <div
      class="line"
      ref="line"
      :style="{ left: line }"
      style="width: 3px"
    ></div>
  </div>
</template>

<script setup>
import { watch, onMounted, ref, nextTick, onBeforeUnmount } from "vue";
const add = ref("");
let process = ref(null);
let line = ref(null);
let box = ref(null);
const props = defineProps({
  list: Object,
});
watch(
  () => props.list,
  (val) => {
    add.value = val;
    nextTick(() => {
      render();
    });
  },
  { deep: true }
);

const render = () => {
  let count = add.value.count;
  // let count = 88;
  process.value = count + "%";
  line.value = Math.ceil((count / 100) * box.value.offsetWidth - 3) + "px";
};
onMounted(() => {
  window.addEventListener("resize", render);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", render);
});
</script>

<style lang="scss" scoped>
.box {
  height: 80px;
  position: relative;
  .access {
    width: 100%;
    height: 30px;
    background: #f48c70;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  .process {
    width: 30%;
    height: 30px;
    background: #45bd80;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  .line {
    width: 3px;
    height: 80px;
    background: #45bd80;
    position: absolute;
    left: calc(30% - 3px);
    top: 0;
  }
}
</style>