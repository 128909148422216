<template>
  <div ref="line" class="line"></div>
</template>
    
    <script setup>
import { ref, onMounted, watch,markRaw } from "vue";
import echarts from "echarts";
let line = ref(null);
let chart = ref(null);

let obj = ref({});

const props = defineProps({
  list: Object,
});

watch(
  () => props.list,
  (val, oldVal) => {
    obj.value = val;
    render();
  },
  { deep: true }
);

const render = () => {
  chart.value = markRaw(echarts.init(line.value));
  let newObj = { ...obj.value };

  let series = newObj.data.map((item) => {
    return {
      name: item.name,
      type: "line",
      smooth: true,
      showSymbol: false,
      symbol: "circle",
      symbolSize: 2,
      data: item.data,
      areaStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1,
            [
              {
                offset: 0,
                color: "#f47070",
              },
              {
                offset: 1,
                color: "#ffe2ce",
              },
            ],
            false
          ),
        },
      },
      itemStyle: {
        normal: {
          color: "#58c8da",
        },
      },
      lineStyle: {
        normal: {
          width: 0,
        },
      },
    };
  });
  let option = {
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        lineStyle: {
          color: "#ddd",
        },
      },
      backgroundColor: "rgba(255,255,255,1)",
      padding: [5, 10],
      textStyle: {
        color: "#7588E4",
      },
      extraCssText: "box-shadow: 0 0 5px rgba(0,0,0,0.3)",
    },
    legend: {
      show: false,
      right: 20,
      orient: "vertical",
      data: ["昨日"],
    },
    xAxis: {
      type: "category",
      data: newObj.xAxis,
      boundaryGap: false,
      splitLine: {
        show: false,
        interval: "auto",
        lineStyle: {
          color: ["#D4DFF5"],
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: "#609ee9",
        },
      },
      axisLabel: {
        show: false,
        margin: 10,
        textStyle: {
          fontSize: 14,
        },
      },
    },
    yAxis: {
      type: "value",
      splitLine: {
        lineStyle: {
          color: ["#D4DFF5"],
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: "#609ee9",
        },
      },
      axisLabel: {
        show: false,
        margin: 10,
        textStyle: {
          fontSize: 14,
        },
      },
      splitLine: {
        show: false,
      },
    },
    series: series,
  };
  chart.value.setOption(option, true);
};
onMounted(() => {
  window.addEventListener("resize", () => {
    if (chart.value != null) {
      chart.value.resize();
    }
  });
});
</script>
    
    <style lang="scss" scoped>
.line {
  height: 80px;
  margin-left: -10px;
  margin-right: -10px;
}
</style>