<template>
  <div class="footer">
    <ul>
      <li>版权: Copyright@中国科学院</li>
      <li>|</li>
      <li>技术支持：中国科学院计算机网络信息中心</li>
      <li>|</li>
      <li>
        <span @click="link('https://beian.miit.gov.cn/')">京ICP备09112257号</span> <span @click="link('http://www.beian.gov.cn/')">京公网安备11010502044069号</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
 const link =(path)=>{
    window.location.href = path;
 }
</script>

<style lang="scss" scoped>
.footer {
  margin-bottom: 10px;
  ul {
    margin: 0 auto;
    display: flex;
    justify-content: center;
   
    li {
      color: #888888;
      margin: 0 15px;
      line-height: 30px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 24px;
      span {
        padding-left: 20px;
      }
      span:hover{
          text-decoration: underline;
          cursor: pointer;
      }
    }
  }
}
</style>